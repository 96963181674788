import { render, staticRenderFns } from "./WebHeader.vue?vue&type=template&id=247b5478&scoped=true&"
import script from "./WebHeader.vue?vue&type=script&lang=js&"
export * from "./WebHeader.vue?vue&type=script&lang=js&"
import style0 from "./WebHeader.vue?vue&type=style&index=0&id=247b5478&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247b5478",
  null
  
)

export default component.exports